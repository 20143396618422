import { Injectable } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, filter, map, first, switchMap, tap, withLatestFrom, forkJoin } from 'rxjs';
import { addMonths, addYears } from 'date-fns';
import {
    OtherProduct,
    CylinderProduct,
    HeatGeneratorProduct,
    HeatLoadCalculationGetById,
    PhotovoltaicAllInOneSystemProduct,
    PhotovoltaicBatteryInverterProduct,
    PhotovoltaicBatteryProduct,
    PhotovoltaicHybridInverterProduct,
    PhotovoltaicModuleInverterProduct,
    PhotovoltaicModuleProduct,
    ProjectResponseGetById,
    Quote,
    QuotesControllerService,
    UpdateProjectRequestPartial,
    GegConfiguration,
    ProjectMigrationDataGetById,
    OrdersControllerService,
    LocationsControllerService,
    AddressValidationMatch,
    AddressValidationRequestPost,
    VariantGetById,
    TariffInformation,
    EnergySourceInformation
} from '@nx-customer-apps/api-planning-projects';
import {
    ProductWithTemplateAttributes,
    ProjectFormValue,
    Message,
    CheckForLockedMessageOptions,
    ProjectAddress,
    OrderWithTemplateAttributes
} from '@nx-customer-apps/shared/interfaces';
import { GlobalSpinnerService } from '../core/components/global-spinner/global-spinner.service';
import {
    ApplicationId,
    ApplicationRoles,
    CountryCode,
    EnergySource,
    EnergySourceTariff,
    ExternalTool,
    HeatLoadCalculationMethod,
    MessageType,
    MigrationSectionType,
    ProductType,
    ProjectStatus,
    ProjectType,
    RoutePaths,
    RouterLinks
} from '@nx-customer-apps/shared/enums';
import { SettingsStore } from '../state/settings/settings.store';
import { EnvironmentService } from './environment.service';
import { MessageService } from './message.service';
import { LodashService, WindowService } from '@nx-customer-apps/shared/services';
import { ProjectStore } from '../state/project/project.store';
import { projectStatusDictionary, ProjectUtils, isOfCountry, isReadOnly } from '@nx-customer-apps/shared/utils';
import { TranslateService } from '@ngx-translate/core';
import { MigrationService } from './migration.service';
import { ExternalToolUrlService } from './external-tool-url.service';
import { AuthService } from './auth.service';
import { ProjectAccessStore } from '../state/project-access/project-access.store';
import { VariantsMapper } from '../projects/project-details/services';
import { RollOutPolicy } from '../policies';

@Injectable({
    providedIn: 'root'
})
export class ProjectsService {
    public setDefaultProjectsLastChangeDateFrom: boolean = true;

    private projectLockedMessageOptions: CheckForLockedMessageOptions;

    constructor(
        private windowService: WindowService,
        private environment: EnvironmentService,
        private quotesControllerService: QuotesControllerService,
        private ordersControllerService: OrdersControllerService,
        private locationsControllerService: LocationsControllerService,
        private settingsStore: SettingsStore,
        private globalSpinnerService: GlobalSpinnerService,
        private route: ActivatedRoute,
        private router: Router,
        private messageService: MessageService,
        private projectStore: ProjectStore,
        private projectAccessStore: ProjectAccessStore,
        private translateService: TranslateService,
        private viewportScroller: ViewportScroller,
        private externalToolUrlService: ExternalToolUrlService,
        private migrationService: MigrationService,
        private variantsMapper: VariantsMapper,
        private authService: AuthService,
        private rollOutPolicy: RollOutPolicy
    ) {}

    public lastChangeDateFrom(): string | undefined {
        const now = new Date();
        const lastChangeDatesFrom = {
            [ApplicationRoles.Admin]: addMonths(now, -1).toISOString(),
            [ApplicationRoles.Default]: addYears(now, -1).toISOString(),
            [ApplicationRoles.NevisDefault]: addYears(now, -1).toISOString()
        };

        const applicationRole: ApplicationRoles | undefined = this.authService.getApplicationRole();
        return applicationRole ? lastChangeDatesFrom[applicationRole] : undefined;
    }

    public getGegConfigurations(project: ProjectResponseGetById): GegConfiguration[] | undefined {
        return project.viguidePlanningSpecificData?.gegModule?.configurations;
    }

    public getHeatloads(project: ProjectResponseGetById): HeatLoadCalculationGetById[] | undefined {
        return project.building.heatLoadCalculations;
    }

    public getSelectedHeatload(project: ProjectResponseGetById): HeatLoadCalculationGetById | undefined {
        const calculations = this.getHeatloads(project);
        if (!calculations) {
            return undefined;
        }
        return calculations.find(item => !!item.isSelected);
    }

    public buildVerifiedBasicInformationRequest(value: ProjectFormValue, project: ProjectResponseGetById): UpdateProjectRequestPartial {
        const migrationVerificationsLeft = project.projectMigration!.migrationSectionsToVerify.filter(
            item =>
                ![MigrationSectionType.BasicData, MigrationSectionType.LocaleData, MigrationSectionType.BuildingAddressData].includes(
                    item as MigrationSectionType
                )
        );
        const projectMigration: ProjectMigrationDataGetById = {
            ...project.projectMigration!,
            migrationSectionsToVerify: migrationVerificationsLeft
        };

        const request: UpdateProjectRequestPartial = {
            building: {
                numberOfPersons: value.residentQuantity
            },
            projectMigration
        };
        if (value.location) {
            const [postalCode, city] = value.location.postalCodeCity! ? value.location.postalCodeCity.split(', ') : [];
            request.address = {
                streetName: value.location?.streetName,
                houseNumber: value.location?.houseNumber,
                postalCode,
                city,
                countryCode: value.location?.countryCode
            };
        }
        return request;
    }

    public buildUpdateProjectRequest(value: ProjectFormValue): UpdateProjectRequestPartial {
        const request: UpdateProjectRequestPartial = {
            projectName: value.projectName,
            projectType: value.projectType,
            building: {
                numberOfPersons: value.residentQuantity,
                buildingType: value.buildingType
            }
        };
        if (value.location) {
            const [postalCode, city] = value.location.postalCodeCity! ? value.location.postalCodeCity.split(', ') : [];
            request.address = {
                streetName: value.location?.streetName,
                houseNumber: value.location?.houseNumber,
                postalCode,
                city,
                countryCode: value.location?.countryCode
            };
        }
        if (value.partnerNumber) {
            request.building!.installer = { partnerNumber: value.partnerNumber };
        }
        return request;
    }

    public getProjectLink(projectId: string): string {
        return `/${RouterLinks.Project}${projectId}`;
    }

    public getHeatPumpLink(projectId: string): string {
        return `/${RouterLinks.Project}${projectId}${RouterLinks.HeatPump}`;
    }

    public getProductsFromProject(project: ProjectResponseGetById): ProductWithTemplateAttributes[] | undefined {
        const plannings = ProjectUtils.getPlanningsWithProductsFromAllVariants(project);

        const products = plannings
            ?.map(planning =>
                planning?.products
                    ?.filter(product => product.productType !== ProductType.Other)
                    .map(product =>
                        this.extendProduct(
                            product,
                            planning['applicationId'] as ApplicationId,
                            project.id!,
                            project.countryCode as CountryCode
                        )
                    )
            )
            .flat(1);
        return products?.length ? (products as ProductWithTemplateAttributes[]) : undefined;
    }

    public hasProducts(project: ProjectResponseGetById, appId: ApplicationId): boolean {
        const allPlannings = ProjectUtils.getPlanningsWithProductsFromSelectedVariant(project);
        const selectedPlanning = allPlannings?.find(planning => planning['applicationId'] === appId);
        return !!selectedPlanning;
    }

    public get projectId(): string {
        return this.route.firstChild?.snapshot.paramMap.get('id')!;
    }

    public initilizeProject(): void {
        const projectId = this.route.firstChild?.snapshot.paramMap.get('id');
        if (!projectId) {
            this.router.navigate([RoutePaths.Error404]);
            return;
        }

        /**
         * Start locking the project flow
         * https://viessmann.atlassian.net/wiki/spaces/CA/pages/140869746/Project+parallel+access
         */
        this.projectAccessStore.lockProject(projectId);
    }

    public isOfCountry(project: ProjectResponseGetById, countryCode: CountryCode): boolean {
        return isOfCountry(project, countryCode);
    }

    public getMessages$(): Observable<Message[]> {
        return this.messageService.messages.pipe(
            tap(messages => {
                const hasError = messages.some(message => message.type === MessageType.Error);
                if (hasError) {
                    this.viewportScroller.scrollToPosition([0, 0]);
                }
            })
        );
    }

    public checkForProjectLockedMessage(options: CheckForLockedMessageOptions): void {
        this.projectLockedMessageOptions = options;
    }

    public checkForMessages(project: ProjectResponseGetById): void {
        this.messageService.init();
        const disabled = this.projectLockedMessageOptions?.locked !== undefined ? this.projectLockedMessageOptions?.locked : false;

        const message = {
            title: 'WARNING_BOX.PROJECT_LOCKED_TITLE',
            content: this.translateService.instant('WARNING_BOX.PROJECT_LOCKED_MESSAGE', {
                lockStartDate: this.projectLockedMessageOptions?.lockStartDate!
                /**
                 * TODO: Replace with user name and user email when BE is ready
                 * Remember to update the translation key with the new placeholders
                 */
                // lockedByUserId: this.projectLockedMessageOptions?.lockedByUserId!
            }),
            type: MessageType.Info,
            uuid: 'projectLocked'
        };

        if (this.projectLockedMessageOptions?.projectLocked) {
            this.messageService.add(message);
        } else {
            this.messageService.remove(message);
        }

        if (this.isRecheckNeeded(project, ApplicationId.HeatPumpPlanner) && !this.migrationService.hasMigrationsToVerify(project)) {
            // TODO: Links or navigation to planners by variantId
            this.messageService.add(
                {
                    title: 'WARNING_BOX.HEAT_PUMP_PLANNER_TITLE',
                    content: 'WARNING_BOX.HEAT_PUMP_PLANNER',
                    type: MessageType.Warning,
                    disabled,
                    buttonLabel: 'WARNING_BOX.REVIEW_HEAT_PUMP_PLANNER',
                    routerLink: `/project/${project.id}/plan-heat-pump`,
                    routerLinkQueryParams: { reconfigure: true }
                },
                { highLevel: true }
            );
        }

        if (this.isRecheckNeeded(project, ApplicationId.PhotovoltaicPlanner) && !this.migrationService.hasMigrationsToVerify(project)) {
            const variantsWithTemplateAttributes = this.variantsMapper.toVariantsWithTemplateAttributes(project.variants);
            const variantsWithRecheckNeeded = this.getRecheckNeededVariants(
                variantsWithTemplateAttributes,
                ApplicationId.PhotovoltaicPlanner
            );
            this.messageService.add(
                {
                    title: 'WARNING_BOX.PHOTOVOLTAIC_PLANNER_TITLE',
                    content: 'WARNING_BOX.PHOTOVOLTAIC_PLANNER',
                    type: MessageType.Warning,
                    links: variantsWithRecheckNeeded.map((variant, id) => ({
                        label: variant.name!,
                        url: this.externalToolUrlService.getExternalToolUrl(ExternalTool.PhotovoltaicPlanner, {
                            projectId: project.id,
                            variantId: variant.id,
                            navigateTo: 'system',
                            countryCode: project.countryCode
                        }),
                        disabled
                    }))
                },
                { highLevel: true }
            );
        }

        if (isReadOnly(project.projectStatus as ProjectStatus)) {
            const projectStatusPhraseKey = projectStatusDictionary[project.projectStatus];
            const projectStatusTranslation = this.translateService.instant(projectStatusPhraseKey);
            const content = this.translateService.instant('WARNING_BOX.READ_ONLY', { status: projectStatusTranslation });
            this.messageService.add({
                title: 'WARNING_BOX.INFO',
                content,
                type: MessageType.Info
            });
        }

        if (this.migrationService.hasMigrationsToVerify(project)) {
            const content = this.translateService.instant('WARNING_BOX.VERIFY_MIGRATION');
            const migrationsQueue = this.migrationService.getQueue(project);
            const contentList = migrationsQueue.toArray().map(verification => verification.value.namePhraseKey);
            const firstQueueItem = migrationsQueue.getFirst();
            this.messageService.add({
                title: 'WARNING_BOX.VERIFY_INFORMATION',
                content,
                contentList,
                type: MessageType.Warning,
                buttonLabel: 'COMMON.BUTTONS.CONTINUE_TO_COMPLETE',
                action: firstQueueItem?.value.navigateTo,
                disabled
            });
        }
    }

    public navigateToQuoteAssist(): void {
        this.globalSpinnerService.next({
            isShown: true,
            loadingText: 'LOADER.TEXT.REQUESTING_QUOTE'
        });

        this.projectStore.project$
            .pipe(
                filter(Boolean),
                first(),
                withLatestFrom(this.settingsStore.languageCode$, (project, languageCode) => ({ project, languageCode })),
                switchMap(({ project, languageCode }) => {
                    const request = {
                        applicationName: this.environment.applicationName,
                        projectId: project.id!
                    };
                    return this.quotesControllerService.quotesControllerCreateQuote({ body: request }).pipe(
                        tap(response => {
                            const quoteUrl = `${response.quoteUrl}&lang=${languageCode}`;
                            this.windowService.changeHref(quoteUrl);
                        })
                    );
                })
            )
            .subscribe();
    }

    public navigateToOrder(orderNumber: string): void {
        this.globalSpinnerService.next({
            isShown: true,
            loadingText: 'LOADER.LOADING'
        });

        const baseUrl = this.environment.getExternalUrl(ExternalTool.PartnerShop);
        const orderUrl = `${baseUrl}/orders/${orderNumber}`;
        this.windowService.changeHref(orderUrl);
    }

    public createOrder(quoteNumber: string): void {
        this.globalSpinnerService.next({
            isShown: true,
            loadingText: 'LOADER.LOADING'
        });

        this.projectStore.project$
            .pipe(
                filter(Boolean),
                first(),
                switchMap(project => {
                    const request = {
                        partnerNumber: project.building.installer!.partnerNumber!,
                        projectId: project.id!,
                        quoteNumber,
                        returnUrl: `${this.environment.baseUrl}${RouterLinks.Project}${project.id}`
                    };
                    return this.ordersControllerService.ordersControllerCreateOrder({ body: request }).pipe(
                        tap(response => {
                            this.windowService.changeHref(response.orderUrl);
                        })
                    );
                })
            )
            .subscribe();
    }

    public getOrders(): Observable<OrderWithTemplateAttributes[]> {
        return this.projectStore.project$.pipe(
            filter(Boolean),
            first(),
            switchMap(project => {
                const request = { projectId: project.id! };
                return this.ordersControllerService.ordersControllerGetOrders(request).pipe(
                    map(response => {
                        return response.orders.map(order => {
                            const planning = ProjectUtils.getPlanningFromSelectedVariantById(project, order.planningId);
                            return { ...order, applicationId: planning?.['applicationId'] as ApplicationId };
                        });
                    })
                );
            })
        );
    }

    public getQuotes(request: { projectId: string; variantId: string }): Observable<Quote[]> {
        setTimeout(() => {
            this.globalSpinnerService.next({
                isShown: true,
                loadingText: 'LOADER.TEXT.LOADING_QUOTES',
                loadingSubtext: 'LOADER.SUBTEXT.LOADING_QUOTES'
            });
        });
        return this.quotesControllerService.quotesControllerGetQuotes(request).pipe(map(response => response.quotes)) as Observable<
            Quote[]
        >;
    }

    public handlePhotovoltaicPlannerAction(variantId?: string): void {
        this.projectStore.project$
            .pipe(
                filter(Boolean),
                first(),
                tap(project => {
                    const projectId = project?.id;
                    const consumption = project?.building.currentHeatingSystem?.consumption;
                    const electricityDemand = project?.building.electricityDemand;
                    if (!LodashService.isNil(consumption) && !LodashService.isNil(electricityDemand)) {
                        const payload = { totalElectricityDemand: consumption! + electricityDemand! };
                        this.savePhotovoltaicIntermediary(variantId!, payload);
                    } else {
                        this.externalToolUrlService.redirectToExternalToolByProjectId(ExternalTool.PhotovoltaicPlanner, {
                            projectId: projectId!,
                            variantId,
                            navigateTo: 'system',
                            countryCode: project?.countryCode!
                        });
                    }
                })
            )
            .subscribe();
    }

    public isNewConstruction(projectType: ProjectType): boolean {
        return projectType === ProjectType.NewConstruction;
    }

    public isRenovation(projectType: ProjectType): boolean {
        return projectType === ProjectType.Renovation;
    }

    public isRecheckNeeded(project: ProjectResponseGetById, applicationId?: ApplicationId): boolean {
        const plannings = ProjectUtils.getPlanningsWithProductsFromAllVariants(project);
        if (!applicationId) {
            return !!plannings?.some(planning => planning.recheckNeeded);
        }

        const planningsByAppId = plannings?.filter(planning => planning['applicationId'] === applicationId);
        return !!planningsByAppId?.some(planning => planning.recheckNeeded);
    }

    public getRecheckNeededVariants(variants: VariantGetById[], applicationId?: ApplicationId): VariantGetById[] {
        return variants.filter(variant => {
            const planningsByAppId = variant?.plannings?.filter(planning => planning.applicationId === applicationId);
            return !!planningsByAppId?.some(planning => planning.recheckNeeded);
        });
    }

    public isCalculatedBy(project: ProjectResponseGetById, calculationMethod: HeatLoadCalculationMethod): boolean {
        const calculations = this.getHeatloads(project);
        const selectedCalculation = calculations?.find(calculation => calculation.isSelected);
        return selectedCalculation?.method === calculationMethod;
    }

    public hasSpecialTarriff(
        project: ProjectResponseGetById,
        options?: { energySource?: EnergySource; tariff?: EnergySourceTariff }
    ): boolean {
        if (!options?.energySource) {
            const anyTariffEnergyPrice = project.energyPrices?.find(item => item.tariffs?.length);
            return Boolean(anyTariffEnergyPrice);
        }

        const energyPrice = project.energyPrices?.find(item => item.energySource === options.energySource);

        if (!options.tariff) {
            return Boolean(energyPrice?.tariffs?.length);
        }

        const hasEnergyPriceOfTariff = Boolean(energyPrice?.tariffs?.find(item => item.tariff === options.tariff));
        return hasEnergyPriceOfTariff;
    }

    public hasHeatPumpElectricityTariff(project: ProjectResponseGetById): boolean {
        return this.rollOutPolicy.hasSeparateHeatPumpEnergyPrice(project.address.countryCode as CountryCode);
    }

    public extendProduct(
        product:
            | CylinderProduct
            | HeatGeneratorProduct
            | PhotovoltaicAllInOneSystemProduct
            | PhotovoltaicBatteryInverterProduct
            | PhotovoltaicBatteryProduct
            | PhotovoltaicModuleInverterProduct
            | PhotovoltaicModuleProduct
            | PhotovoltaicHybridInverterProduct
            | OtherProduct,
        appId: ApplicationId,
        projectId: string,
        countryCode: CountryCode
    ): ProductWithTemplateAttributes {
        let link: string | undefined;
        let applicationId: ApplicationId | undefined;
        switch (appId) {
            case ApplicationId.HeatPumpPlanner:
                link = this.externalToolUrlService.getExternalToolUrl(ExternalTool.HeatPumpPlanner, {
                    projectId,
                    navigateTo: 'system',
                    countryCode
                });
                applicationId = ApplicationId.HeatPumpPlanner;

                break;
            case ApplicationId.PhotovoltaicPlanner:
                link = this.externalToolUrlService.getExternalToolUrl(ExternalTool.PhotovoltaicPlanner, {
                    projectId,
                    navigateTo: 'location',
                    countryCode
                });
                applicationId = ApplicationId.PhotovoltaicPlanner;
        }
        return { ...product, link, applicationId } as ProductWithTemplateAttributes;
    }

    public getValidAddresses(location: ProjectAddress): Observable<AddressValidationMatch> {
        this.globalSpinnerService.next({
            isShown: true,
            loadingText: 'LOADER.TEXT.VALIDATING_ADDRESS',
            loadingSubtext: 'LOADER.SUBTEXT.VALIDATING_ADDRESS'
        });

        const [postalCode, city] = location.postalCodeCity! ? location.postalCodeCity.split(', ') : [];

        const request: AddressValidationRequestPost = {
            streetName: location.streetName!,
            houseNumber: location.houseNumber!,
            postalCode,
            city,
            countryCode: location.countryCode!
        };
        return this.locationsControllerService.locationsControllerValidateAddress({ body: request as AddressValidationRequestPost }).pipe(
            map(response => {
                if (response.addressMatches.length < 2) {
                    return response.addressMatches[0];
                }
                return response.addressMatches.reduce((curr, prev) => {
                    return curr.matchScore > prev.matchScore ? curr : prev;
                });
            })
        );
    }

    public getSourceTariff(energyPrice: EnergySourceInformation, searchTariff: EnergySourceTariff): TariffInformation | undefined {
        return energyPrice?.tariffs?.find(item => item.tariff === searchTariff);
    }

    public hasHeatPumpPriceATariff(project: ProjectResponseGetById, searchTariffs: EnergySourceTariff | EnergySourceTariff[]): boolean {
        const heatPumpPrice = project.energyPrices?.find(price => price.energySource === EnergySource.HeatPumpElectricity);
        if (Array.isArray(searchTariffs)) {
            return Boolean(heatPumpPrice?.tariffs?.find(item => searchTariffs.includes(item.tariff as EnergySourceTariff)));
        } else {
            return Boolean(heatPumpPrice?.tariffs?.find(item => item.tariff === searchTariffs));
        }
    }

    private savePhotovoltaicIntermediary(variantId: string, payload: { totalElectricityDemand: number }): void {
        const projectPatch = {
            variantId,
            request: {
                building: {}
            }
        };

        projectPatch.request.building = {
            ...projectPatch.request.building,
            totalElectricityDemand: payload.totalElectricityDemand
        };

        this.projectStore.savePhotovoltaicIntermediary(projectPatch);
    }
}
