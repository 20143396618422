import { Injectable } from '@angular/core';
import { map, Observable, take, first, switchMap, filter } from 'rxjs';
import {
    BuildingType,
    HeatLoadCalculationMethod,
    ConsumptionProfile,
    EnergySource,
    ProjectType,
    RoofType,
    HeatGeneratorType,
    GEGSystemType,
    HeatloadHeaders,
    BuildingPlacement
} from '@nx-customer-apps/shared/enums';
import {
    AppState,
    HeatloadState,
    ModernizationsAndInsulation,
    RoofDimensions,
    SuccessMessageOptions
} from '@nx-customer-apps/shared/interfaces';
import { TranslateService } from '@ngx-translate/core';
import { FullPartial, windowPercentageAreaDictionary, centimetersToMeters } from '@nx-customer-apps/shared/utils';
import {
    BuildingGetById,
    BuildingPartial,
    BuildingsControllerService,
    CalculationsControllerService,
    CurrentHeatingSystemGetById,
    DevicesControllerService,
    EnergySourceInformation,
    HeatGeneratorEnergySourceInformation,
    EnergyStandard,
    HeatGeneratorInformation,
    HeatLoadByBuildingHullRequestPost,
    HeatLoadByBuildingHullResponse,
    HeatLoadByConsumptionRequestPost,
    HeatLoadByConsumptionResponse,
    HeatLoadByCustomValueRequestPost,
    HeatLoadByCustomValueResponse,
    HeatLoadByEnergyCertRequestPost,
    HeatLoadByEnergyCertResponse,
    HeatLoadByLivingAreaRequestPost,
    HeatLoadByLivingAreaResponse,
    HeatLoadCalculationGetById,
    ProjectResponseGetById,
    Roof,
    RoofPartial,
    SystemItem,
    SystemsRequestPost,
    UpdateProjectRequestPartial,
    WindowInformation
} from '@nx-customer-apps/api-planning-projects';
import { LodashService } from '@nx-customer-apps/shared/services';
import { ProjectStore } from '../../state/project/project.store';
import { GlobalStore } from '../../state/global/global.store';
import { DEFAULT_CONSUMPTION_PROFILE, DEFAULT_EXHAUST_GAS_LOSSES } from '../../shared/utils/constants';
import { ActivatedRoute } from '@angular/router';
import { ProjectsService } from '../../services';

@Injectable({
    providedIn: 'root'
})
export class HeatloadService {
    constructor(
        private translate: TranslateService,
        private calculationsControllerService: CalculationsControllerService,
        private devicesControllerService: DevicesControllerService,
        private buildingsControllerService: BuildingsControllerService,
        private projectStore: ProjectStore,
        private route: ActivatedRoute,
        private globalStore: GlobalStore,
        private projectService: ProjectsService
    ) {}

    public isNewConstruction(projectType: ProjectType): boolean {
        return projectType === ProjectType.NewConstruction;
    }

    public getHeatGenerators(gegSystemType?: GEGSystemType): Observable<HeatGeneratorInformation[]> {
        return this.projectStore.project$.pipe(
            first(),
            switchMap(project => {
                const countryCode = project!.address.countryCode;
                return this.devicesControllerService
                    .devicesControllerGetHeatGenerators({ countryCode: countryCode as 'DE' | 'BE' | 'AT', gegSystemType })
                    .pipe(map(response => response.heatGenerators));
            })
        ) as Observable<HeatGeneratorInformation[]>;
    }

    public getHeatingSystems(
        energySource: EnergySource,
        installationConstructionYear: number,
        heatGeneratorType: HeatGeneratorType,
        gegSystemType?: GEGSystemType
    ): Observable<SystemItem[]> {
        return this.projectStore.project$.pipe(
            filter(Boolean),
            first(),
            switchMap(project => {
                const countryCode = project!.address.countryCode;
                const request: SystemsRequestPost = {
                    countryCode,
                    energySource,
                    installationConstructionYear,
                    heatGeneratorType,
                    gegSystemType
                } as SystemsRequestPost;
                return this.devicesControllerService
                    .devicesControllerGetSystems({ body: request as SystemsRequestPost })
                    .pipe(map(response => response.systems));
            })
        );
    }

    public getConsumptionProfiles(projectType: ProjectType, buildingType: BuildingType): Observable<ConsumptionProfile[]> {
        return this.projectStore.project$.pipe(
            first(),
            switchMap(project => {
                const countryCode = project!.address.countryCode;
                const request = { countryCode, projectType, buildingType };
                return this.buildingsControllerService
                    .buildingsControllerConsumptionProfiles(
                        request as {
                            buildingType: 'ONE_FAMILY_HOUSE' | 'MULTI_FAMILY_HOUSE';
                            countryCode: 'DE' | 'BE' | 'AT';
                            projectType: 'MODERNISATION' | 'NEW_BUILDING';
                        }
                    )
                    .pipe(map(response => response.profiles));
            })
        ) as Observable<ConsumptionProfile[]>;
    }

    public getWindows(
        windowsConstructionYearProvidedByUser: string | undefined,
        buildingConstructionYear: string | undefined
    ): Observable<WindowInformation[]> {
        return this.projectStore.project$.pipe(
            first(),
            switchMap(project => {
                const countryCode = project!.address.countryCode;
                const projectCreateDate = new Date(project!.createdDate!).getFullYear();
                const windowsConstructionYear = windowsConstructionYearProvidedByUser
                    ? +windowsConstructionYearProvidedByUser
                    : buildingConstructionYear
                    ? +buildingConstructionYear
                    : projectCreateDate;

                const request = {
                    countryCode,
                    windowsConstructionYear
                };
                return this.buildingsControllerService
                    .buildingsControllerWindows(
                        request as {
                            countryCode: 'DE' | 'BE' | 'AT';
                            windowsConstructionYear: number;
                        }
                    )
                    .pipe(map(response => response.windowTypes));
            })
        ) as Observable<WindowInformation[]>;
    }

    public getEnergyStandards(): Observable<EnergyStandard[]> {
        return this.projectStore.project$.pipe(
            first(),
            switchMap(project => {
                const countryCode = project!.address.countryCode;
                const request = { countryCode };
                return this.buildingsControllerService
                    .buildingsControllerEnergyStandards(request)
                    .pipe(map(response => response.energyStandards));
            })
        );
    }

    public calculateByConsumption(): Observable<HeatLoadByConsumptionResponse> {
        return this.globalStore.state$.pipe(
            map(state => {
                const {
                    heatload: { heatGeneratorDetails, energyConsumption, heatingEnergySource, heatingDistribution, selectedHeatingSystem },
                    project: { selectedProject }
                } = state;

                let request = {
                    buildingType: selectedProject!.building.buildingType,
                    energySourceConsumption: energyConsumption?.requiredEnergyPerYear,
                    electricityConsumption: energyConsumption?.nonHeatingElectricityPerYear,
                    exhaustGasLosses: heatGeneratorDetails?.gasLossExhaust || DEFAULT_EXHAUST_GAS_LOSSES,
                    heatingSystemType: heatGeneratorDetails?.boilerType,
                    lowTemperatureOption: !!heatGeneratorDetails?.lowTemperature,
                    warmWaterByHeatGenerator: !!heatGeneratorDetails?.warmWaterByHeatGenerator,
                    heatGeneratorPower: heatGeneratorDetails?.nominalPower,
                    heatPumpOperatingMode: heatGeneratorDetails?.heatPumpOperatingMode,
                    installationConstructionYear: heatGeneratorDetails ? +heatGeneratorDetails.installationYear! : undefined,
                    consumptionProfileForHeating: heatingDistribution?.heatingSchedule,
                    numberOfPersons: selectedProject!.building?.numberOfPersons,
                    postalCode: selectedProject!.address!.postalCode,
                    countryCode: selectedProject!.address!.countryCode,
                    energyPrices: this.energyPricesToConsumptionRequest(selectedProject!.energyPrices!),
                    energySource: heatingEnergySource?.selectedEnergySource.type
                } as HeatLoadByConsumptionRequestPost;

                request = this.addOptionalDailyLockPeriodProperty<HeatLoadByConsumptionRequestPost>(request, selectedProject);

                return request;
            }),
            take(1),
            switchMap(request =>
                this.calculationsControllerService.calculationsControllerConsumption({
                    body: request as HeatLoadByConsumptionRequestPost
                })
            )
        ) as Observable<HeatLoadByConsumptionResponse>;
    }

    public calculateByLivingArea(): Observable<HeatLoadByLivingAreaResponse> {
        return this.globalStore.state$.pipe(
            map(state => {
                const {
                    heatload,
                    project: { selectedProject }
                } = state;
                let request = {
                    buildingType: selectedProject!.building.buildingType,
                    projectType: selectedProject!.projectType,
                    livingArea: heatload.livingArea!.value,
                    numberOfPersons: selectedProject!.building.numberOfPersons,
                    countryCode: selectedProject!.address!.countryCode,
                    postalCode: selectedProject!.address.postalCode,
                    projectCreationYear: new Date(selectedProject?.createdDate!).getFullYear()
                } as HeatLoadByLivingAreaRequestPost;

                request = this.addOptionalDailyLockPeriodProperty<HeatLoadByLivingAreaRequestPost>(request, selectedProject);
                request = this.addOptionalBuildingPlacementProperty(request, selectedProject);

                if (selectedProject?.projectType === ProjectType.NewConstruction) {
                    request.energyStandard = heatload.selectedEnergyStandard?.key;
                    return request;
                }

                const buildingConstructionYear = +heatload.modernization!.buildingConstructionYear;
                request.buildingConstructionYear = buildingConstructionYear;
                request.wallsModernizationYear = +heatload.modernization!.wallsModernization || buildingConstructionYear;
                request.windowsModernizationYear = +heatload.modernization!.windowsModernization || buildingConstructionYear;
                request.roofModernizationYear = +heatload.modernization!.roofModernization || buildingConstructionYear;
                request.ventilationHeatRecovery = heatload.ventilation!.value;
                return request;
            }),
            take(1),
            switchMap(request =>
                this.calculationsControllerService.calculationsControllerLivingArea({
                    body: request as HeatLoadByLivingAreaRequestPost
                })
            )
        ) as Observable<HeatLoadByLivingAreaResponse>;
    }

    public calculateByEnergyCertificate(): Observable<HeatLoadByEnergyCertResponse> {
        return this.globalStore.state$.pipe(
            map(state => {
                const {
                    heatload,
                    project: { selectedProject }
                } = state;
                let request = {
                    buildingType: selectedProject!.building.buildingType,
                    countryCode: selectedProject!.address!.countryCode,
                    postalCode: selectedProject!.address.postalCode,
                    numberOfPersons: selectedProject!.building.numberOfPersons,
                    transmissionHeatLoss: heatload.energyCertificate!.specificTransmissionHeatLoss,
                    buildingVolume: heatload.energyCertificate!.buildingVolume,
                    buildingEnclosureArea: heatload.energyCertificate!.surroundingArea,
                    airExchangeRate: heatload.energyCertificate!.airExchangeRate
                } as HeatLoadByEnergyCertRequestPost;

                request = this.addOptionalDailyLockPeriodProperty<HeatLoadByEnergyCertRequestPost>(request, selectedProject);

                return request;
            }),
            take(1),
            switchMap(request =>
                this.calculationsControllerService.calculationsControllerEnergyCertificate({
                    body: request as HeatLoadByEnergyCertRequestPost
                })
            )
        ) as Observable<HeatLoadByEnergyCertResponse>;
    }

    public calculateByBuildingHull(): Observable<HeatLoadByBuildingHullResponse> {
        return this.globalStore.state$.pipe(
            map(state => {
                const {
                    heatload,
                    project: { selectedProject }
                } = state;

                const projectCreationYear = new Date(selectedProject?.createdDate!).getFullYear();
                let request = {
                    countryCode: selectedProject!.address!.countryCode,
                    postalCode: selectedProject!.address.postalCode,
                    numberOfPersons: selectedProject!.building.numberOfPersons,
                    projectCreationYear,
                    buildingType: selectedProject!.building.buildingType,
                    buildingPlacement: heatload.buildingHull!.buildingPosition,
                    buildingLength: heatload.buildingHull!.buildingDimensions.buildingLength,
                    buildingWidth: heatload.buildingHull!.buildingDimensions.buildingWidth,
                    buildingLevelHeight: heatload.buildingHull!.buildingDimensions.floorHeight,
                    buildingLevelsCount: heatload.buildingHull!.buildingDimensions.numberOfFloors,
                    ventilationHeatRecovery: !!heatload.buildingHull!.ventilationAndHeating.ventilationPresent,
                    hasCellar: !!heatload.buildingHull!.ventilationAndHeating.basementPresent,
                    heatedCellar: !!heatload.buildingHull!.ventilationAndHeating.basementHeated,
                    hasAttic: !!heatload.buildingHull!.ventilationAndHeating.atticPresent,
                    heatedAttic: !!heatload.buildingHull!.ventilationAndHeating.atticHeated,
                    windowsArea: windowPercentageAreaDictionary[heatload.windowsAndGlazing!.windowsPercentageArea],
                    windowsType: heatload.windowsAndGlazing!.typeOfGlazing,
                    roof: this.roofToBuildingHullCalculation(heatload)
                } as HeatLoadByBuildingHullRequestPost;

                request = this.addOptionalDailyLockPeriodProperty<HeatLoadByBuildingHullRequestPost>(request, selectedProject);
                request = this.addConsumptionProfileForHeatingProperty(request, selectedProject);

                if (selectedProject?.projectType === ProjectType.Renovation) {
                    const modernizations = this.stateToBuildingHullModernization(heatload.modernizationsAndInsulation!);
                    const requestWithModernization = {
                        ...request,
                        ...modernizations
                    };
                    return requestWithModernization as HeatLoadByBuildingHullRequestPost;
                }
                const insulations = this.stateToInsulation(heatload.modernizationsAndInsulation!);
                const requestWithoutModernization = {
                    ...request,
                    ...insulations
                };
                return requestWithoutModernization as HeatLoadByBuildingHullRequestPost;
            }),
            take(1),
            switchMap(request =>
                this.calculationsControllerService.calculationsControllerBuildingHull({
                    body: request as HeatLoadByBuildingHullRequestPost
                })
            )
        ) as Observable<HeatLoadByBuildingHullResponse>;
    }

    public calculateByCustomValue(): Observable<HeatLoadByCustomValueResponse> {
        return this.globalStore.state$.pipe(
            map(state => {
                const {
                    heatload,
                    project: { selectedProject }
                } = state;
                let request = {
                    buildingType: selectedProject!.building.buildingType,
                    customBaseHeatLoad: +heatload.definedValue!,
                    numberOfPersons: selectedProject!.building.numberOfPersons
                } as HeatLoadByCustomValueRequestPost;

                request = this.addOptionalDailyLockPeriodProperty<HeatLoadByCustomValueRequestPost>(request, selectedProject);

                return request;
            }),
            take(1),
            switchMap(request => this.calculationsControllerService.calculationsControllerCustomValue({ body: request }))
        ) as Observable<HeatLoadByCustomValueResponse>;
    }

    /**
     * Builds request payload for patching project with calculated heatload and other information gathered in heatload state.
     */
    public stateToSaveHeatload(
        state: AppState,
        buildingType: BuildingType,
        heatloadResult: Partial<HeatLoadCalculationGetById>,
        includeCalculations?: boolean
    ): UpdateProjectRequestPartial {
        const {
            heatload,
            project: { selectedProject }
        } = state;
        const calculationMethod = this.getCalculationMethod(heatload);
        const heatLoadCalculation = this.buildHeatloadCalculations(calculationMethod!, heatloadResult, buildingType);
        const heatLoadCalculations = this.addOrReplaceHeatLoad(selectedProject, heatLoadCalculation);
        const options = includeCalculations ? { state, heatLoadCalculations } : { state };
        if (calculationMethod === HeatLoadCalculationMethod.Consumption) {
            return this.stateToHeatloadByConsumption(options);
        }
        if (calculationMethod === HeatLoadCalculationMethod.LivingSpace) {
            return this.stateToHeatloadByLivingArea(options);
        }
        if (calculationMethod === HeatLoadCalculationMethod.EnergyCertificate) {
            return this.stateToHeatloadByEnergyCertificate(options);
        }
        if (calculationMethod === HeatLoadCalculationMethod.BuildingHull) {
            return this.stateToHeatloadByBuildingHull(options);
        }
        if (calculationMethod === HeatLoadCalculationMethod.CustomValue) {
            const definedValueOptions = includeCalculations ? { heatloadResult, heatLoadCalculations } : { heatloadResult };
            return this.stateToHeatloadByDefinedValue(definedValueOptions);
        }
        return {};
    }

    /**
     * Builds request payload for patching project with manually provided result by user.
     */
    public stateToHeatloadByDefinedValue(options: {
        heatloadResult: Partial<HeatLoadCalculationGetById>;
        heatLoadCalculations?: HeatLoadCalculationGetById[];
    }): UpdateProjectRequestPartial {
        const building: Partial<BuildingGetById> = { customBaseHeatLoad: options.heatloadResult.baseHeatLoad };
        if (options.heatLoadCalculations) {
            building.heatLoadCalculations = options.heatLoadCalculations;
        }
        return { building };
    }

    /**
     * It returns a building element construction year if it is already given or @param year in otherwise
     */
    public getElementConstructionYear(year: number, value: any, field: string, subfield?: string): number {
        if (subfield) {
            return this.getElementConstructionYear(year, value[field], subfield);
        }
        if (!value[field]) {
            return year;
        }
        return value[field] >= year ? value[field] : year;
    }

    public getInsulationTitle(projectType: ProjectType): string {
        return projectType === ProjectType.NewConstruction ? 'HEATLOAD.INSULATION.TITLE' : 'HEATLOAD.MODERNIZATIONS_AND_INSULATION.TITLE';
    }

    public getModernizationsAndInsulationDescription(projectType: ProjectType): string | null {
        return projectType === ProjectType.Renovation ? 'HEATLOAD.MODERNIZATIONS_AND_INSULATION.DESCRIPTION' : null;
    }

    public getHeader(options: {
        recalculationMethod?: HeatLoadCalculationMethod;
        calculationMethod?: HeatLoadCalculationMethod;
    }): HeatloadHeaders {
        switch (options.recalculationMethod) {
            case HeatLoadCalculationMethod.LivingSpace:
                return HeatloadHeaders.RecalcByLivingSpace;
            case HeatLoadCalculationMethod.BuildingHull:
                return HeatloadHeaders.RecalcByBuildingHull;
            case HeatLoadCalculationMethod.Consumption:
                return HeatloadHeaders.RecalcByConsumption;
            case HeatLoadCalculationMethod.EnergyCertificate:
                return HeatloadHeaders.RecalcByEnergyCertificate;
            case HeatLoadCalculationMethod.CustomValue:
                return HeatloadHeaders.RecalcByCustomValue;
        }

        switch (options.calculationMethod) {
            case HeatLoadCalculationMethod.LivingSpace:
                return HeatloadHeaders.ByLivingArea;
            case HeatLoadCalculationMethod.BuildingHull:
                return HeatloadHeaders.ByBuildingHull;
            case HeatLoadCalculationMethod.Consumption:
                return HeatloadHeaders.ByConsumption;
            case HeatLoadCalculationMethod.EnergyCertificate:
                return HeatloadHeaders.ByCertificate;
            case HeatLoadCalculationMethod.CustomValue:
                return HeatloadHeaders.HeatingLoad;
        }

        return HeatloadHeaders.HeatingLoad;
    }

    private energyPricesToConsumptionRequest = (energyPrices: EnergySourceInformation[]): Partial<EnergySourceInformation>[] => {
        return energyPrices.map(price => {
            return {
                energyCosts: price.energyCosts,
                energySource: price.energySource
            };
        });
    };

    /**
     * Builds an array of heatload calculations for patching project data.
     */
    private buildHeatloadCalculations(
        calculationMethod: HeatLoadCalculationMethod,
        heatloadResult: Partial<HeatLoadCalculationGetById>,
        buildingType: BuildingType
    ): HeatLoadCalculationGetById {
        const calculation: HeatLoadCalculationGetById = {
            method: calculationMethod,
            baseHeatLoad: heatloadResult.baseHeatLoad || 0,
            lockTimeHeatLoad: heatloadResult.lockTimeHeatLoad || 0,
            orderDate: new Date().toISOString()
        } as HeatLoadCalculationGetById;

        if (buildingType === BuildingType.SingleFamilyHouse) {
            calculation.warmWaterHeatLoad = heatloadResult.warmWaterHeatLoad || 0;
        }

        return calculation;
    }

    /**
     * Builds request payload for patching project with calculated heatload and specific information from energy certificate calculation method gathered in heatload state.
     */
    private stateToHeatloadByEnergyCertificate(options: {
        state: AppState;
        heatLoadCalculations?: HeatLoadCalculationGetById[];
    }): UpdateProjectRequestPartial {
        const {
            heatload: { energyCertificate }
        } = options.state;

        const building: Partial<BuildingPartial> = {
            energyCertificate
        };
        if (options.heatLoadCalculations) {
            building.heatLoadCalculations = options.heatLoadCalculations;
        }
        return { building };
    }

    /**
     * Builds request payload for patching project with calculated heatload and specific information from building hull calculation method gathered in heatload state.
     */
    private stateToHeatloadByBuildingHull(options: {
        state: AppState;
        heatLoadCalculations?: HeatLoadCalculationGetById[];
    }): UpdateProjectRequestPartial {
        const {
            heatload: { buildingHull, roofType, roofDimensions, modernizationsAndInsulation, windowsAndGlazing },
            project: { selectedProject }
        } = options.state;

        const building: Partial<BuildingPartial> = {
            buildingPlacement: buildingHull!.buildingPosition,
            buildingLevelHeight: buildingHull!.buildingDimensions.floorHeight,
            buildingLevelsCount: buildingHull!.buildingDimensions.numberOfFloors,
            buildingLength: buildingHull!.buildingDimensions.buildingLength,
            buildingWidth: buildingHull!.buildingDimensions.buildingWidth,
            hasAttic: !!buildingHull?.ventilationAndHeating.atticPresent,
            heatedAttic: !!buildingHull?.ventilationAndHeating.atticHeated,
            hasCellar: !!buildingHull?.ventilationAndHeating.basementPresent,
            heatedCellar: !!buildingHull?.ventilationAndHeating.basementHeated,
            hasVentilation: !!buildingHull?.ventilationAndHeating.ventilationPresent,
            windowsType: windowsAndGlazing!.typeOfGlazing,
            windowsArea: windowPercentageAreaDictionary[windowsAndGlazing!.windowsPercentageArea],
            roof: this.stateToRoof(roofType?.value, roofDimensions)
        };

        if (options.heatLoadCalculations) {
            building.heatLoadCalculations = options.heatLoadCalculations;
        }

        if (!selectedProject?.building.consumptionProfileForHeating) {
            building.consumptionProfileForHeating = DEFAULT_CONSUMPTION_PROFILE;
        }

        if (selectedProject!.projectType === ProjectType.Renovation) {
            const modernizations = this.stateToBuildingHullModernization(modernizationsAndInsulation!);
            const buildingWithModernizations = {
                ...building,
                ...modernizations
            };

            return { building: buildingWithModernizations };
        }

        const insulations = this.stateToInsulation(modernizationsAndInsulation!);
        const buildingWithoutModernizations = {
            ...building,
            ...insulations
        };

        return { building: buildingWithoutModernizations };
    }

    private stateToInsulation(insulations: ModernizationsAndInsulation): Partial<BuildingPartial> {
        return {
            roofInsulation: centimetersToMeters(+insulations!.roofInsulation!),
            atticInsulation: centimetersToMeters(+insulations!.atticInsulation!),
            wallsInsulation: centimetersToMeters(+insulations!.wallsInsulation!),
            cellarInsulation: centimetersToMeters(+insulations!.cellarInsulation!)
        };
    }

    private stateToBuildingHullModernization(modernizations: ModernizationsAndInsulation): Partial<BuildingPartial> {
        const buildingConstructionYear = +modernizations!.buildingConstructionYear!;
        const request: Partial<BuildingPartial> = {
            buildingConstructionYear,
            windowsConstructionYear: +modernizations!.windowsConstructionYear! || buildingConstructionYear,
            wallsConstructionYear: +modernizations!.wallsRenovationAndInsulation?.wallsConstructionYear! || buildingConstructionYear,
            roofConstructionYear: +modernizations!.roofRenovationAndInsulation!.roofConstructionYear || buildingConstructionYear,
            atticConstructionYear: +modernizations!.upperFloorRenovationAndInsulation!.atticConstructionYear || buildingConstructionYear,
            roofInsulation: centimetersToMeters(+modernizations!.roofRenovationAndInsulation!.roofInsulation),
            atticInsulation: centimetersToMeters(+modernizations!.upperFloorRenovationAndInsulation!.atticInsulation),
            wallsInsulation: centimetersToMeters(+modernizations!.wallsRenovationAndInsulation!.wallsInsulation!)
        };

        const basementRenovationAndInsulation = modernizations.basementRenovationAndInsulation;

        if (basementRenovationAndInsulation) {
            request.cellarConstructionYear = +basementRenovationAndInsulation.cellarConstructionYear || buildingConstructionYear;
            request.cellarInsulation = centimetersToMeters(+basementRenovationAndInsulation.cellarInsulation);
        }

        return request;
    }

    private stateToRoof(type: RoofType | undefined, roof: RoofDimensions | undefined): Roof {
        return {
            type: type || RoofType.Flat,
            jambWallHeight: roof?.heightOfJamb,
            slope1: roof?.inclination1 || roof?.inclination,
            slope2: roof?.inclination2,
            height1: roof?.roofHeight1,
            height2: roof?.roofHeight2,
            s1: roof?.roofLength
        };
    }

    /**
     * Builds request payload for patching project with calculated heatload and specific information from consumption calculation method gathered in heatload state.
     */
    private stateToHeatloadByConsumption(options: {
        state: AppState;
        heatLoadCalculations?: HeatLoadCalculationGetById[];
    }): UpdateProjectRequestPartial {
        const {
            heatload: { heatGeneratorDetails, heatingEnergySource, heatingDistribution, energyConsumption }
        } = options.state;
        const currentHeatingSystem: CurrentHeatingSystemGetById = {
            heatingSystemType: heatGeneratorDetails?.boilerType,
            energySource: heatingEnergySource?.selectedEnergySource.type,
            installationConstructionYear: heatGeneratorDetails ? +heatGeneratorDetails.installationYear! : undefined,
            isLowTemperature: heatGeneratorDetails?.lowTemperature,
            heatGeneratorPower: heatGeneratorDetails?.nominalPower,
            exhaustGasLosses: heatGeneratorDetails?.gasLossExhaust || DEFAULT_EXHAUST_GAS_LOSSES,
            consumption: energyConsumption?.requiredEnergyPerYear,
            heatPumpOperatingMode: heatGeneratorDetails?.heatPumpOperatingMode
        };
        const building: Partial<BuildingPartial> = {
            warmWaterByHeatGenerator: heatGeneratorDetails?.warmWaterByHeatGenerator,
            heatingType: heatingDistribution?.distributionMethod,
            flowTemperature: heatingDistribution?.heatingFlowTemperature,
            electricityDemand: energyConsumption?.nonHeatingElectricityPerYear,
            consumptionProfileForHeating: heatingDistribution?.heatingSchedule,
            currentHeatingSystem
        };
        if (options.heatLoadCalculations) {
            building.heatLoadCalculations = options.heatLoadCalculations;
        }
        return { building };
    }

    /**
     * Builds request payload for patching project with calculated heatload and specific information from living area calculation method gathered in heatload state.
     */
    private stateToHeatloadByLivingArea(options: {
        state: AppState;
        heatLoadCalculations?: HeatLoadCalculationGetById[];
    }): UpdateProjectRequestPartial {
        const {
            heatload,
            project: { selectedProject }
        } = options.state;
        const building: FullPartial<BuildingPartial> = {
            livingArea: heatload.livingArea!.value
        };
        if (options.heatLoadCalculations) {
            building.heatLoadCalculations = options.heatLoadCalculations;
        }
        if (selectedProject!.projectType === ProjectType.NewConstruction) {
            building.energyStandard = heatload.selectedEnergyStandard?.key;
            return { building };
        }
        const buildingConstructionYear = +heatload.modernization!.buildingConstructionYear;
        const buildingWithRenovation = {
            ...building,
            buildingConstructionYear,
            windowsConstructionYear: +heatload.modernization!.windowsModernization || buildingConstructionYear,
            wallsConstructionYear: +heatload.modernization!.wallsModernization || buildingConstructionYear,
            roofConstructionYear: +heatload.modernization!.roofModernization || buildingConstructionYear,
            hasVentilation: heatload.ventilation!.value
        };
        return { building: buildingWithRenovation };
    }

    private addOptionalBuildingPlacementProperty(
        request: HeatLoadByLivingAreaRequestPost,
        selectedProject: ProjectResponseGetById | undefined
    ) {
        const buildingPlacement = selectedProject?.building?.buildingPlacement as BuildingPlacement;

        if (!LodashService.isNil(buildingPlacement)) {
            request.buildingPlacement = buildingPlacement;
        }

        return request;
    }

    private addConsumptionProfileForHeatingProperty(
        request: HeatLoadByBuildingHullRequestPost,
        selectedProject: ProjectResponseGetById | undefined
    ) {
        const consumptionProfileForHeating = selectedProject?.building?.consumptionProfileForHeating as ConsumptionProfile;

        request.consumptionProfileForHeating = !LodashService.isNil(consumptionProfileForHeating)
            ? consumptionProfileForHeating
            : DEFAULT_CONSUMPTION_PROFILE;

        return request;
    }

    private addOptionalDailyLockPeriodProperty = <T extends { dailyLockPeriod?: number }>(
        request: T,
        selectedProject: ProjectResponseGetById | undefined
    ): T => {
        const heatPumpElectrictyPice = selectedProject!.energyPrices!.find(
            energyPrice => energyPrice.energySource === EnergySource.HeatPumpElectricity
        );
        const lockPeriodTariff = heatPumpElectrictyPice?.tariffs?.find(tariff => !LodashService.isNil(tariff.dailyLockPeriod));

        if (lockPeriodTariff) {
            request.dailyLockPeriod = lockPeriodTariff.dailyLockPeriod;
        }

        return request;
    };

    private roofToBuildingHullCalculation = (heatload: HeatloadState): RoofPartial => {
        const { roofDimensions, roofType } = heatload;
        const roof: RoofPartial = {
            type: roofType?.value || RoofType.Flat
        };
        if (!LodashService.isNil(roofDimensions?.heightOfJamb)) {
            roof.jambWallHeight = roofDimensions?.heightOfJamb;
        }
        const basicInclination = roofDimensions?.inclination1 || roofDimensions?.inclination;
        if (!LodashService.isNil(basicInclination)) {
            roof.slope1 = basicInclination;
        }
        if (!LodashService.isNil(roofDimensions?.inclination2)) {
            roof.slope2 = roofDimensions?.inclination2;
        }
        if (!LodashService.isNil(roofDimensions?.roofHeight1)) {
            roof.height1 = roofDimensions?.roofHeight1;
        }
        if (!LodashService.isNil(roofDimensions?.roofHeight2)) {
            roof.height2 = roofDimensions?.roofHeight2;
        }
        if (!LodashService.isNil(roofDimensions?.roofLength)) {
            roof.s1 = roofDimensions?.roofLength;
        }
        return roof;
    };

    public getSuccessMessage(options: SuccessMessageOptions): string {
        if (!options.message && !options.suffix) {
            throw Error('Provide at least one option - "message" or "suffix" or "value"');
        }
        if (options.message && options.suffix) {
            return this.translate.instant(options.message, { value: options.suffix });
        }
        if (!options.message && options.suffix) {
            return options.suffix;
        }
        return this.translate.instant(options.message as string);
    }

    public getEnergySources(generators: HeatGeneratorInformation[], searchValue: string): HeatGeneratorEnergySourceInformation[] {
        const heatGenerator = generators.find(generator => generator.heatGeneratorType === searchValue);
        if (!heatGenerator) {
            throw Error('Energy sources are not found in the provided heat generators');
        }
        return heatGenerator.energySources;
    }

    public getSelectedSource(
        sources: HeatGeneratorEnergySourceInformation[],
        searchValue?: string
    ): HeatGeneratorEnergySourceInformation | undefined {
        if (!searchValue) {
            return;
        }
        return sources.find(source => source.type === searchValue);
    }

    public getSelectedSourceFromGenerators(
        generators: HeatGeneratorInformation[],
        generatorType: HeatGeneratorType,
        energySourceType?: EnergySource
    ): HeatGeneratorEnergySourceInformation {
        const generator = generators.find(item => item.heatGeneratorType === generatorType) as HeatGeneratorInformation;
        const isManySources = generator.energySources.length > 1;
        return isManySources ? generator.energySources.find(source => source.type === energySourceType)! : generator.energySources[0];
    }

    public isHeatGeneratorAHeatPump(heatGenerator: HeatGeneratorType, energySource: EnergySource): boolean {
        return heatGenerator === HeatGeneratorType.HeatPump && energySource === EnergySource.HeatPumpElectricity;
    }

    public isHeatGeneratorAnElectricStorageHeater(heatGenerator: HeatGeneratorType, energySource: EnergySource): boolean {
        return (
            heatGenerator === HeatGeneratorType.ElectricHeater &&
            [EnergySource.HeatPumpElectricity, EnergySource.Electricity].includes(energySource)
        );
    }

    public isRecalculation(): boolean {
        return Boolean(this.route.firstChild?.firstChild?.snapshot.queryParamMap.get('method'));
    }

    private getCalculationMethod(heatload: HeatloadState): HeatLoadCalculationMethod {
        if (!LodashService.isNil(heatload.definedValue)) {
            return HeatLoadCalculationMethod.CustomValue;
        }
        return heatload.calculationMethod!.value;
    }

    private addOrReplaceHeatLoad(
        selectedProject: ProjectResponseGetById | undefined,
        heatLoad: HeatLoadCalculationGetById
    ): HeatLoadCalculationGetById[] {
        if (selectedProject?.building?.heatLoadCalculations) {
            const heatLoadCalculations = selectedProject.building.heatLoadCalculations.filter(
                heatLoadCalculation => heatLoadCalculation.method !== heatLoad.method
            ) as HeatLoadCalculationGetById[];
            return [...heatLoadCalculations, heatLoad];
        }
        return [heatLoad];
    }
}
