import { Action, createReducer, on } from '@ngrx/store';
import { AppSpecificDataState, ReportConfiguration } from '@nx-customer-apps/shared/interfaces';
import { AppSpecificDataActions } from './app-specific-data.actions';

import data from '../../../../../../package.json';
import { defaultReportConfiguration } from './default-report-configuration';

export const initialAppSpecificDataState: AppSpecificDataState = {
    viguidePlanningSpecificData: {
        data: {
            reportConfiguration: defaultReportConfiguration
        }
    }
};

export const processConfiguration = (
    projectConfiguration: ReportConfiguration[],
    reportConfiguration: ReportConfiguration[]
): ReportConfiguration[] => {
    if (!projectConfiguration) {
        return reportConfiguration;
    }

    return projectConfiguration.reduce<ReportConfiguration[]>((acc, projectConf) => {
        const matchingReportConf = reportConfiguration.find(r => r.section === projectConf.section);

        if (!matchingReportConf) {
            return acc;
        }

        let filteredSubSections: ReportConfiguration[] | undefined;
        if (projectConf.subSections && matchingReportConf.subSections) {
            filteredSubSections = processConfiguration(projectConf.subSections, matchingReportConf.subSections);
        }

        acc.push({
            ...projectConf,
            subSections: filteredSubSections
        });
        return acc;
    }, []);
};

const reducer = createReducer(
    initialAppSpecificDataState,
    on(AppSpecificDataActions.setAppSpecificData, (state, { viguidePlanningSpecificData }) => {
        return {
            viguidePlanningSpecificData: {
                ...viguidePlanningSpecificData,
                data: {
                    ...viguidePlanningSpecificData.data,
                    reportConfiguration: processConfiguration(
                        (<any>viguidePlanningSpecificData?.data)?.reportConfiguration,
                        defaultReportConfiguration
                    )
                },
                applicationVersion: viguidePlanningSpecificData.applicationVersion || data.version
            }
        };
    }),
    on(AppSpecificDataActions.setReportConfiguration, (state, { reportConfiguration }) => {
        return {
            ...state,
            viguidePlanningSpecificData: {
                ...state.viguidePlanningSpecificData,
                data: {
                    reportConfiguration
                }
            }
        };
    })
);

export function appSpecificDataReducer(state: AppSpecificDataState | undefined, action: Action) {
    return reducer(state, action);
}
